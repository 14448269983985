import React from "react";
import ListItem from "../components/list-item/list-item.js";
import "../styles/pages/privacy-policy.scss";
import Layout from "../layouts/index.js";
import Banner from "../components/banner/banner.js";
import { cookies } from "../utils/legals/cookieInformation"

const CookiePolicy = () => {

    return (
        <Layout
            SEOTitle={"Cookie Policy - United Legal Assistance"}
            SEODescription={"Most websites you visit will use cookies in order to improve your user experience by enabling that website to ‘remember’ you, either for the duration of your visit (using a ‘session cookie’) or for repeat visits (using a ‘persistent cookie’)."}
            footerDisclaimer={""}>

            <div className="privacy-policy-container">

                <div style={{ margin: "40px 0" }}>
                    <h1 className="main-title">Cookie Policy</h1>
                    <b />
                </div>

                {
                    cookies.length ? cookies.map((item, index) => {

                        return (
                            <div key={index}>
                                {
                                    item.title ? <h3 className="bold">{item.title}</h3> : ""

                                }
                                {
                                    item.description ? Array.isArray(item.description) ? item.description.map((itemText, index) => {

                                        return (
                                            <>
                                                <h3 style={{ fontSize: "18px", margin: "10px 0 0 0", fontWeight: "bold", color: "#666" }}>{itemText.subtitle}</h3>
                                                <p style={{ margin: "5px 0" }} key={index}>{itemText.text}</p>
                                            </>
                                        )
                                    }) : <p style={{ margin: "10px 0px 0px 0px" }}>{item.description}</p> : ""
                                }
                                {
                                    item.itemList ?
                                        <div style={{ paddingTop: "10px" }}>
                                            {
                                                item.itemList?.length ? item.itemList.map((listItem, index) => {

                                                    return (
                                                        <>
                                                            {listItem.title ? <ListItem text={listItem.title} key={index} /> : ""}
                                                            <p style={{ margin: "0px" }}>{listItem.description}</p>
                                                        </>
                                                    )
                                                }) : ""
                                            }

                                        </div>
                                        : ""
                                }
                            </div>
                        )
                    }) : ""
                }
            </div>
        </Layout>
    )
}

export default CookiePolicy;