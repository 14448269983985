import React from "react";
import "./list-item.scss";

function ListItem({text}){
    
    return(

        <li>{text}</li>
    )
}

export default ListItem;